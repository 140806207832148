<template>
  <div>
    <hr v-if="!spinner">
    <b-spinner
        v-if="spinner"
        variant="primary"
        class="spinners"
    />
    <div v-if="!spinner">
      <b-row class="mt-1">
        <b-col lg="5" md="6" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Ana Sayfa Slider Listesi</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <table class="table table-striped mt-1">
              <thead>
              <tr>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  SLİDER YAZI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  RESİM ÖN İZLEME
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  LİNK
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}">
                  LİNK YAZI
                </th>
                <th scope="col" :style="{backgroundColor:$store.getters.tableTitle,color:'white'}"/>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in $store.getters.sliderList"
                  :key="index"
              >
                <td>{{ item.baslik }}</td>
                <td>
                  <b-img
                      :src="imagePath(item.fotograf)"
                      fluid
                      alt="Responsive image"
                  />
                </td>
                <td>{{ item.link }}</td>
                <td>{{ item.linkYazi }}</td>
                <td>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="bgDanger"
                      class="btn-icon rounded-circle mr-1"
                      size="sm"
                      @click="sliderSil(item.sliderID)"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </b-card-actions>
        </b-col>
        <b-col lg="7" md="6" sm="12" cols="12">
          <b-card-actions
              class="cari-liste-card"
          >
            <b-row class="mt-50 mb-3">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block">Slider Kaydı</h4>
                </b-col>
              </div>
            </b-row>
            <hr class="mt-0">
            <b-row class="mt-2" :key="count">
              <b-col cols="12">
                <b-form-group label="Zemin">
                  <v-select
                      v-model="zemin"
                      :components="{ OpenIndicator, Deselect }"
                      label="title"
                      :options="option"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" md="12" sm="12" cols="12">
                <b-form-group
                    label="Slider Fotoğrafı"
                    label-for="basicInput"
                >
                  <b-form-file
                      type="file"
                      placeholder="Dosya Seçiniz"
                      drop-placeholder="Drop file here..."
                      accept=".jpg, .png, .gif, .jpeg"
                      :file-name-formatter="formatNames"
                      @change="onFileSelected"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Fotoğraf Genişliği"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model.number="kayit.fotoGenislik"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="4" md="6" sm="12" cols="12">
                <b-form-group
                    label="Fotoğraf Yüksekliği"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="kayit.fotoYukseklik"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Üst Başlık"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="kayit.ustBaslik"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Alt Başlık"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="kayit.altBaslik"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Link"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="kayit.link"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                    label="Link Yazısı"
                    label-for="basicInput"
                >
                  <b-form-input
                      id="basicInput"
                      v-model="kayit.linkYazisi"
                      autocomplete="off"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-progress
                    v-model="value"
                    max="100"
                />
              </b-col>
              <b-col
                  cols="12"
                  class="text-right"
              >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="bgInfo"
                    class="mt-1 border-0"
                    :style="{backgroundColor:$store.getters.bgSuccess}"
                    @click="onUpload"
                >
                  Kaydet
                </b-button>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BProgress,
  BRow,
  BCol,
  BCard,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormCheckbox,
  BFormCheckboxGroup,
  BTable,
  BModal,
  BImg,
  BInputGroup,
  BContainer,
  BFormInput,
  BPagination,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormFile, BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BCardActions,
    BProgress,
    BButton,
    BContainer,
    BAvatar,
    BSpinner,
    BCard,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTable,
    BModal,
    BInputGroup,
    BFormFile,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count: 0,
      spinner: true,
      Deselect: {
        render: createElement => createElement('span', ''),
      },
      OpenIndicator: {
        render: createElement => createElement('span', ''),
      },
      kayit: {
        fotoGenislik: '1920',
        fotoYukseklik: '980',
        ustBaslik: '',
        altBaslik: '',
        link: '',
        linkYazisi: '',
      },
      zemin: {
        title: 'Siyah Zemin',
        id: 0
      },
      option: [
        {
          title: 'Siyah Zemin',
          id: 0
        },
        {
          title: 'Beyaz Zemin',
          id: 1
        },
      ],
      value: 0,
      selectedFile: [],
    }
  },
  computed: {
    salaryListItems() {
      return this.$store.state.webPanel.slider.liste
    },
  },
  created() {
    this.$store.dispatch('slider')
        .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(res)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          }
        })
        .catch(err => {
          this.spinner = false
          const mesaj = this.$store.getters.notificationSettings(err)
          if (mesaj) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                text: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          }
        })
  },
  beforeDestroy() {
    this.$store.commit('clearSlider')
  },
  methods: {
    imagePath(path) {
      return `${this.$store.state.API_URL}userFiles/${localStorage.getItem('otelFirmaKodu')}/Galeri/${path}`
    },
    onUpload() {
      if (this.selectedFile[0]) {
        const fd = new FormData()
        fd.append('resim', this.selectedFile[0] || '')
        fd.append('crm_token', localStorage.getItem('otelUserToken'))
        fd.append('serviceName', 'web')
        fd.append('methodName', 'sliderYonetimi')
        fd.append('zemin', this.zemin.id)
        fd.append('g', this.kayit.fotoGenislik)
        fd.append('y', this.kayit.fotoYukseklik)
        fd.append('ustBaslik', this.kayit.ustBaslik)
        fd.append('baslik', this.kayit.altBaslik)
        fd.append('link', this.kayit.link)
        fd.append('linkYazi', this.kayit.linkYazisi)
        fd.append('sliderKaydi', true)
        axios
            .post(store.state.POST_URL, fd, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: function (progressEvent) {
                this.value = parseInt(
                    Math.round((progressEvent.loaded / progressEvent.total) * 95),
                )
              }.bind(this),
            })
            .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
              if (res.data.result.status == 200) {
                this.$store.dispatch('slider')
                this.selectedFile = [];
                (this.value = 0),
                    (this.zemin = {
                      title: 'Siyah Zemin',
                      id: 0,
                    }),
                    (this.kayit.fotoGenislik = '570'),
                    (this.kayit.fotoYukseklik = '300'),
                    (this.kayit.ustBaslik = ''),
                    (this.kayit.altBaslik = ''),
                    (this.kayit.link = ''),
                    (this.kayit.linkYazisi = '')
                this.count++
                const mesaj = this.$store.getters.notificationSettings(res.data)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              } else {
                const mesaj = this.$store.getters.notificationSettings(res.data)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            })
            .catch(err => {
              (this.value = 0)
              const mesaj = this.$store.getters.notificationSettings(err)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Hata!',
            text: 'Lütfen resim ekleyiniz',
            icon: 'ThumbsUpIcon',
            variant: 'danger',
          },
        })
      }
    },
    sliderSil(val) {
      this.$store
          .dispatch('sliderSil', { id: val })
          .then((res, position = localStorage.getItem('otelSuccessReturn')) => {
            const mesaj = this.$store.getters.notificationSettings(res)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Başarılı',
                text: mesaj,
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            }, { position })
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Hata!',
                title: mesaj,
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            })
          })
    },
    onFileSelected(event) {
      this.selectedFile.push(event.target.files[0])
    },
    formatNames(files) {
      return files.length === 1
          ? files[0].name
          : `${files.length} dosya seçildi`
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style scoped>
.table th,
.table td {
  max-width: 160px !important;
}

.row {
  justify-content: end !important;
}

[dir] .table th,
[dir] .table td {
  padding: 3px !important;
  border-top: 1px solid #ebe9f1;
  max-width: 450px !important;
}

.card-header {
  background-color: #2e83c9;
  height: 30px;
  position: relative;
}

.card-header h4 {
  position: absolute;
  color: white;
  margin-top: 10px;
}

.card-body {
  padding: 10px 15px;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
.table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color: white;
}

thead {
  text-align: center;
}
</style>
